import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
  UserCredential,
} from "firebase/auth";
import React, { ChangeEventHandler, ReactNode, useState } from "react";
import profileDatabase, { IProfile } from "../../database/ProfileDatabase";
import { auth } from "../../Firebase";

function TextField(props: {
  name: string;
  title: string;
  target: Record<string, string>;
  disabled?: boolean;
  type?: string;
  placeholder?: string;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
}) {
  const {
    disabled = false,
    type,
    placeholder,
    name,
    title,
    target,
    required,
  } = props;
  return (
    <>
      <div className="form-floating">
        <input
          type={type}
          disabled={disabled}
          className="form-control"
          required={required}
          id={name}
          placeholder={placeholder}
          onChange={(e) => (target[name] = e.target.value)}
          minLength={props.minLength}
          maxLength={props.maxLength}
        />
        <label htmlFor={name}>{title}</label>
      </div>
      <br />
    </>
  );
}

function Checkbox(props: {
  name: string;
  title: ReactNode;
  target: Record<string, string>;
  required?: boolean;
  disabled?: boolean;
}) {
  const { name, title, target, required, disabled } = props;
  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    target[name] = e.target.checked ? "Sim" : "Não";
  };
  return (
    <>
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          value="Sim"
          id={name}
          name={name}
          onChange={onChange}
          required={required}
          disabled={disabled}
        />
        <label className="form-check-label" htmlFor={name}>
          {title}
        </label>
      </div>
    </>
  );
}

export default function Register() {
  const [state, setState] = useState({
    sending: false,
    error: "",
    success: "",
    target: { marketingAuthorized: "Não" } as Record<string, string>,
  });
  const { sending, error, success, target } = state;

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setState({ sending: true, error: "", success: "", target: target });

    const { name, email, password, passwordConfirmation } = target;
    const {
      phone,
      company,
      position,
      zipCode,
      city,
      country,
      dataProcessAuthorized,
      marketingAuthorized,
    } = target;

    if (!email || !password) return;
    if (password !== passwordConfirmation) {
      setState({
        sending: false,
        error: "Password and confirmation don't match.",
        success: "",
        target: target,
      });
      return;
    }

    console.log("setei estado");
    createUserWithEmailAndPassword(auth, email, password)
      .then((credential: UserCredential) => {
        if (!credential.user) {
          setState({
            sending: false,
            error: "Invalid username or password.",
            success: "",
            target: target,
          });
        } else {
          updateProfile(credential.user, { displayName: name });
          const profile: IProfile = {
            id: credential.user.uid,
            name,
            email,
            phone,
            company,
            position,
            zipCode,
            city,
            country,
            role: "user",
            dataProcessAuthorized,
            marketingAuthorized,
          };
          profileDatabase.setProfile(profile);
          sendEmailVerification(credential.user)
            .then(() => {
              setState({
                sending: false,
                error: "",
                success:
                  "A confirmation link has been sent to your e-mail address.",
                target: target,
              });
            })
            .catch((err) => {
              setState({
                sending: false,
                error: err.message || err.toString(),
                success: "",
                target: target,
              });
            });
        }
      })
      .catch((error2) => {
        setState({
          sending: false,
          error: error2.message || error2,
          success: "",
          target: target,
        });
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit} style={{ paddingTop: "20px" }}>
        <TextField
          name="name"
          placeholder="Name Lastname"
          required
          target={target}
          title="Full Name*:"
          disabled={sending}
        />
        <TextField
          name="email"
          placeholder="name@domain.com"
          required
          target={target}
          title="E-mail address*:"
          type="email"
          disabled={sending}
        />
        <TextField
          name="phone"
          placeholder="(xx) 12345-1234"
          required
          target={target}
          title="Phone number (area code) + number*:"
          type="text"
          disabled={sending}
          minLength={10}
          maxLength={30}
        />
        <TextField
          name="company"
          placeholder="My company name"
          required
          target={target}
          title="Your company name*:"
          type="text"
          disabled={sending}
          minLength={2}
          maxLength={30}
        />
        <TextField
          name="position"
          placeholder="Ex: Floor Manager"
          required
          target={target}
          title="Position*:"
          type="text"
          disabled={sending}
          minLength={3}
          maxLength={30}
        />
        <TextField
          name="zipCode"
          placeholder="Ex: 12345678"
          required
          target={target}
          title="Zip Code (only numbers)*:"
          type="text"
          disabled={sending}
          minLength={8}
          maxLength={8}
        />
        <TextField
          name="city"
          placeholder="Ex: Atlanta"
          required
          target={target}
          title="City Name*:"
          type="text"
          disabled={sending}
          minLength={5}
          maxLength={8}
        />
        <TextField
          name="country"
          placeholder="Ex: EUA"
          required
          target={target}
          title="Country*:"
          type="text"
          disabled={sending}
          minLength={5}
          maxLength={30}
        />
        <TextField
          name="password"
          placeholder="[password]"
          required
          target={target}
          title="Password*:"
          type="password"
          disabled={sending}
          minLength={6}
          maxLength={30}
        />
        <TextField
          name="passwordConfirmation"
          placeholder="[password]"
          required
          target={target}
          title="Password Confirmation*:"
          type="password"
          disabled={sending}
          minLength={6}
          maxLength={30}
        />
        <Checkbox
          name="dataProcessAuthorized"
          title={
            <span>
              I acknowledge and agree that my personal data will be processed in accordance with the {" "}
              <a
                href="https://discover.3ds.com/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                3DS Privacy Policy
              </a>{" "}
              and I am aware that, for business purposes, 3DS may share it with trusted 
              stakeholders (including 
              {" "}
              <a
                href="https://www.3ds.com/partners/locate-partner"
                target="_blank"
                rel="noreferrer"
              >
                Dassault Systèmes
              </a>{" "}          
              and {" "}
              <a
                href="https://www.solidworks.com/how-to-buy/find-solidworks-reseller/"
                target="_blank"
                rel="noreferrer"
              >
                SolidWorks
              </a>{" "}          
              business partners and event sponsors, as detailed on the registration forms).
            </span>
          }
          required
          disabled={sending}
          target={target}
        />
        <br />
        <Checkbox
          name="marketingAuthorized"
          title={
            <span>
              I agree to receive marketing communications about 3DS 
              products or services.
            </span>
          }
          disabled={sending}
          target={target}
        />

        {sending ? (
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              style={{ width: "100%" }}
            />
          </div>
        ) : null}
        {error ? (
          <div className="alert alert-danger" role="alert">
            <h4 className="alert-heading">Ooops!</h4>
            <p>{error}</p>
          </div>
        ) : null}
        {success ? (
          <div className="alert alert-success" role="alert">
            <h4 className="alert-heading">Well Done!</h4>
            <p>{success}</p>
          </div>
        ) : null}
        <div style={{ padding: "20px 0px" }}>
          <button type="submit" className="btn btn-primary" disabled={sending}>
            Submit
          </button>
        </div>
      </form>
    </>
  );
}
