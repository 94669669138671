import { makeAutoObservable } from "mobx";
import { SIMULATION_ID } from "../constants";
import enrollmentDatabase from "../database/EnrollmentDatabase";
import { IEnrollment } from "../types/EnrollmentInterface";
import { authenticationState } from "./Authentication";

class ProfileState {
    enrollmentsLoading = false;
    enrollments: IEnrollment[] = [];
    enrollmentsError?: string;

    constructor() {
        makeAutoObservable(this);
    }

    setLoading(){
        this.enrollmentsLoading = true;
        this.enrollmentsError = undefined;
        this.enrollments = [];
    }
    setEnrolments(enrollments: IEnrollment[]){
        this.enrollments = enrollments;
        this.enrollmentsLoading = false;
    }

}

export const profileState = new ProfileState();

export const loadEnrolments = async () =>{
    profileState.setLoading();
    const enrollments = await enrollmentDatabase.getUserSimulationEnrollments(
      authenticationState.email ?? "",
      SIMULATION_ID
    );
    profileState.setEnrolments(enrollments);
  }
  