import { observer } from "mobx-react-lite";
import { useState } from "react";
import { InputBox } from "../../components/Inputs";
import { allTracks } from "../../database/TrackDatabase";
import { selectTrack } from "../../services/Enrollment";
import { ITrack } from "../../types/TrackInterface";
import TrackCard from "./TrackCard";

export default observer(function TrackSelect() {
  const [eventWrapper, setEventWrapper] = useState({
    event: "",
    state: "",
  } as Record<string, any>);
  const onTrackSelect = (track: ITrack) => {
    console.log(eventWrapper);
    if (!eventWrapper.event) {
      setEventWrapper({
        event: eventWrapper.event,
        state: "invalid",
      });
    } else {
      selectTrack(track, eventWrapper.event);
    }
  };
  return (
    <div>
      <div className="card p-2">
        <InputBox
          label="What event are you participating in?"
          value={eventWrapper.event}
          state={eventWrapper.state}
          onChange={(value) => {
            setEventWrapper({ event: value, state: "valid" });
          }}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
        {allTracks.map((track, index) => (
          <TrackCard track={track} key={index} onSelect={onTrackSelect} />
        ))}
      </div>
    </div>
  );
});
