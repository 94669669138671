import { IQuestion } from "../../types/QuestionInterface";

export default {
  questionId: "3ds_q12",
  context: [
    "As you all know, recent changes in demand have greatly changed our production mix.",
    "We had an increase in the frequency of new products and/or variations thereof and, consequently, operating costs due to the impact on process times and not quality.",
    "We need to improve our ability to understand, identify and react to the factors that are impacting our operational deviations to ensure our level of service.",
  ],
  content: [
    "Where do we have room to improve communication between areas that support LEAN within our process?",
  ],
  options: [
    {
      text: "We just need to review and improve our production management methods.",
      xValue: 0.25,
      yValue: 0.25,
      feedbackContent:
        "To improve communication and understanding of how the company should behave, production management methods such as Six Sigma, Lean Manufacturing, PDCA are time-consuming and not simple to implement, having a system that controls and provides indicators of improvement points it is extremely valuable for it to be constant and equivalent to the evolution of all areas.",
    },
    {
      text: "We have to integrate the different management methods supporting Lean Manufacturing.",
      xValue: 0.5,
      yValue: 0.5,
      feedbackContent:
        "Investment in integrations and improvements in the IT area is a good way out, but they are extra costs that could be mitigated with technologies already present on the market, such as cloud management tools, which do not require any extra cost and do not require the integration of different methods. to support yourself.",
    },
    {
      text: "We just need to implement or improve our IT systems to provide greater visibility into the root cause of deviations and prioritize actions.",
      xValue: 0.75,
      yValue: 0.75,
      extension: {
        type: "OpenAnswer",
        title:
          "Is any IT tool currently used to support production management systems?",
      },
      feedbackContent:
        "Investment in integrations and improvements in the IT area is a good way out, but they are extra costs that could be mitigated with technologies already present on the market, such as cloud management tools, which do not require any extra cost and do not require the integration of different methods. to support yourself.",
    },
    {
      text: "We already have some systems for managing LEAN Manufacturing and we need to improve their use.",
      xValue: 1,
      yValue: 1,
      extension: {
        type: "OpenAnswer",
        title:
          "Is any IT tool currently used to support production management systems?",
      },
      feedbackContent:
        "The concept of Lean Manufacturing, if followed, can transform a company in all areas, together with a tool in which it can be possible to allocate all relevant information and have an interaction of the teams in favor of reducing costs, eliminating waste and maintaining the product quality.",
    },
  ],
  characterName: "Marcelo - Quality Manager",
  sceneNumber: 9,
} as IQuestion;
