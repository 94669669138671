import { observer } from "mobx-react-lite";
import { useRef } from "react";
import ActionSuspense from "../../components/ActionSuspense";
import { authenticationState, signInEmailPassword } from "../../services/Authentication";

export default observer(function LoginWithEmailPassword(props: { onRegister: () => void, onForgotPassword: () => void }) {
    const emailRef = useRef(null);
    const passwordRef = useRef(null);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();
      const emailField: any = emailRef?.current;
      const email = emailField?.value;
      const passwordField: any = passwordRef?.current;
      const password = passwordField?.value;
      signInEmailPassword(email, password);
    }

    return (
      <>
        <form onSubmit={handleSubmit} style={{ paddingTop: "20px" }}>
          <div className="form-floating">
            <input
              type="email"
              disabled={authenticationState.authLoading}
              className="form-control"
              required
              id="floatingInput"
              placeholder="name@example.com"
              ref={emailRef}
            />
            <label htmlFor="floatingInput">E-mail address*:</label>
          </div>
          <br />
          <div className="form-floating">
            <input
              type="password"
              disabled={authenticationState.authLoading}
              className="form-control"
              required
              id="floatingPassword"
              placeholder="[senha]"
              ref={passwordRef}
            />
            <label htmlFor="floatingPassword">Password*:</label>
          </div>
          <div style={{ padding: "20px 0px" }}>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={authenticationState.authLoading}
            >
              Log In
            </button>
            <br />
            <br />
            <ActionSuspense
              loading={authenticationState.authLoading}
              error={authenticationState.authError}
              success={authenticationState.authSuccess}
            />
          </div>
          <div style={{ padding: "10px 0px" }}>
            <div>
              Don&apos;t have an account?{" "}
              <button
                className="btn btn-link"
                style={{ marginTop: "-3px" }}
                onClick={props.onRegister}
              >
                Register
              </button>
            </div>
            <div>
              Forgot your password?{" "}
              <button
                className="btn btn-link"
                style={{ marginTop: "-3px" }}
                onClick={props.onForgotPassword}
              >
                Reset password
              </button>
            </div>
          </div>
        </form>
      </>
    );
});
