import { observer } from "mobx-react-lite";
import AppLayout from "../../components/AppLayout";
import { questionState } from "../../services/Question";
import QuestionAnswer from "./QuestionAnswer";
import QuestionButtons from "./QuestionButtons";
import TextPopper from "./TextPopper";

export default observer(function QuestionAsk() {
  const { scene, questionContent, questionCharacter } = questionState;
  const { questionBackground, answerBackground, questionPos } = scene;

  return (
    <AppLayout
      backgroundImg={
        questionState.answerOption ? answerBackground : questionBackground
      }
    >
      <TextPopper
        visible={true}
        position={questionPos}
        content={questionContent ?? ""}
        title={questionCharacter ?? ""}
        placement={scene.questionDirection}
      />
      {questionState.answerOption ? <QuestionAnswer /> : null}
      <QuestionButtons />
    </AppLayout>
  );
});
