import { IQuestion } from "../../types/QuestionInterface";

export default {
  questionId: "3ds_q7",
  context: [
    "We received a question from a client here in the Sustainability committee about our vision of certifications such as ISO 14,000 and internal actions aimed at reducing environmental impact.",
  ],
  content: [
    "For this reason, I am collecting with all areas what their initiatives for this theme.",
  ],
  options: [
    {
      text: "Unfortunately, so far the company has not been able to measure our environmental impact and we will need the Committee to tackle this issue.",
      xValue: 0,
      yValue: 0,
      feedbackContent:
        "It is of paramount importance to comply with legislation and standardized quality certifications worldwide. Sustainability is a very present agenda and it would be important to measure the impact that the company has, in order to always seek to reduce pollution, providing balance and protection of the environment.",
    },
    {
      text: "In my department we are able to measure the impact we are having, but I know that many areas cannot measure the same.",
      xValue: 0.25,
      yValue: 0.5,
      extension: {
        type: "OpenAnswer",
        title:
          "Could you tell me a little more about how you do this environmental impact measurement process in your area? What kind of data and reports can you extract?",
      },
      feedbackContent:
        "It is interesting to implement environmental impact control within some sectors of the company, but the ideal is always to seek to work in a sustainable way in all areas and throughout the product development process.",
    },
    {
      text: "We can measure the impact from the extraction of raw material, waste, etc., to the final delivery of the product.",
      xValue: 0.75,
      yValue: 0.75,
      extension: {
        type: "OpenAnswer",
        title:
          "Could you tell me a little more about how you do this environmental impact measurement process in your area? What kind of data and reports can you extract?",
      },
      feedbackContent:
        "Measuring the environmental impact and being an ISO 14,000 certified company brings credibility to other companies that may be possible partners, to customers and helps the planet become a less polluted place.",
    },
  ],
  characterName: "Marcondes - Environmental Manager",
  sceneNumber: 4,
} as IQuestion;
