import { NavLink } from "react-router-dom";
import AppLayout from "../../components/AppLayout";
import Preload from "./Preload";

export default function Home() {
  return (
    <>
      <AppLayout backgroundImg="/img/scene11_factory_manu01c.png">
        <div className="container">
          <div className="col-sm-8">
            <div
              style={{
                marginTop: "20px",
                padding: "20px",
                backgroundColor: "rgba(255, 255, 255, 0.66)",
                width: "100%",
                textAlign: "left",
              }}
            >
              <h1><b>3D</b>EXPERIENCE Game</h1>
              <div className="" style={{ fontWeight: "500" }}>
                <p>
                  Hello, we want to invite you to participate in the <b>3D</b>EXPERIENCE Game!
                </p>
                <p>
                  The entire world has been affected by the events of the last two years,
                  so today more than ever companies need to adapt to the new normal 
                  and new market demands more 
                  quickly and efficiently.
                </p>
                <p>
                  Participate in the <b>3D</b>EXPERIENCE Game, a dynamic, fast,
                  interactive activity and through it, get some insights into 
                  your company's profile. At the end, receive a diagnosis of  
                  how your company is currently in terms of productivity and innovation
                  compared to companies in the same sector, in addition to recommendations
                  from Dassault Systèmes consultants.
                </p>
                <p>Fill in the form and enjoy the <b>3D</b>EXPERIENCE Game!</p>
              </div>
              <div style={{ padding: "5px" }}>
                <NavLink className="btn btn-primary" to={"/simulation"}>
                  Start
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </AppLayout>
      <Preload />
    </>
  );
}
