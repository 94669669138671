import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import ActionSuspense from "../../components/ActionSuspense";
import { authenticationState } from "../../services/Authentication";
import enrollmentState, { ensureEnrollment } from "../../services/Enrollment";
import Authentication from "../authentication/AuthenticationWall";
import Complete from "./Complete";
import Introduction from "./Introduction";
import Question from "./Question";


const SimulationContents = observer(() => {
    const { enrollment } = enrollmentState;
    if (!enrollment) return <div>We could not find the enrollment.</div>;
    switch (enrollment.status) {
        case "Respondendo": return <Question />;
        case "Completo": return <Complete />;
        default: return <Introduction />;
    }

});


export default observer(function Simulation() {
    const email = authenticationState.emailVerified ? authenticationState.email : undefined;
    useEffect(() => {
        ensureEnrollment(email || undefined);
    }, [email]);
    return (
      <Authentication>
        <ActionSuspense
          loading={enrollmentState.loading}
          error={enrollmentState.error}
        >
          <SimulationContents />
        </ActionSuspense>
      </Authentication>
    );
});