import { IQuestion } from "../../types/QuestionInterface";

export default {
  questionId: "3ds_q10",
  context: [
    "We have an opportunity to win a great customer from our main competitor, however, a crucial point for them is the virtual validation of some aspects of the use of products such as: Mechanical Efforts and Fluid Behavior.",
  ],
  content: ["Can we do this kind of virtual validation?"],
  options: [
    {
      text: "Unfortunately no, we currently only do manual calculations as part of our development process.",
      xValue: 0,
      yValue: 0,
      feedbackContent:
        "Making manual validations, in addition to being a very complex activity that may require a delay in project delivery, the risk of failure is very high, which can bring complications and result in rework. Currently, it is essential to be competitive in the market to have simulation tools available, mainly Mechanical Efforts and Fluid Behavior.",
    },
    {
      text: "We only have tools for basic simulations. We were unable to reach the required level of complexity.",
      xValue: 0.5,
      yValue: 0.25,
      feedbackContent:
        "It is very important to have this innovative and sustainable mindset, but investing in a level of precision with advanced simulations without the need for extra costs would be ideal, having control of the entire development process from design creation, durability tests and quality, until the delivery of this product.",
    },
    {
      text: "When necessary, we use an external partner to handle this type of demand.",
      xValue: 0.25,
      yValue: 0.5,
      feedbackContent:
        "It is very important to have this innovative and sustainable mindset, but investing in a level of precision with advanced simulations without the need for extra costs would be ideal, having control of the entire development process from design creation, durability tests and quality, until the delivery of this product.",
    },
    {
      text: "Yes! We can do all required validations.",
      xValue: 1,
      yValue: 1,
      feedbackContent:
        "Companies that validate their products with simulation absurdly reduce the cost with prototypes, reach a high level of sustainability, as they do not generate waste and do not pollute, reduce the time of arrival of the product to the market, not to mention the opportunity to have reports on the characteristics of the product.",
    },
  ],
  backgroundImage: "/img/cafeteria/bg-scene-gray-room.jpg",
  characterName: "Dolores - Commercial Director",
  sceneNumber: 11,
} as IQuestion;
