import { IQuestion } from "../../types/QuestionInterface";

export default {
  questionId: "3ds_q8-e",
  context: [
    "We have a project to reduce costs and service time for maintenance activities. Two critical points for this issue are the high cost of transporting qualified professionals to answer basic maintenance,",
    "calls as well as the creation and translation into different languages ​​of printed manuals with maintenance procedures.",
  ],
  content: [
    " Is there currently any initiative to optimize the creation of maintenance procedures in order to help us in optimizing the aforementioned points?",
  ],
  options: [
    {
      text: "No, today we create all maintenance procedures in the traditional way, that is, they are printed using photos of the equipment and we translate the documents into each requested language.",
      xValue: 0,
      yValue: 0,
      feedbackContent:
        "Within the optimization proposal, not having documentation and intellectual property available in the cloud for interested employees makes it difficult to perform the work, and keeping printed manuals implies certifications on the environment, sustainability, which can open up many opportunities in the future.",
    },
    {
      text: "We are adopting the replacement of photos by images of 3D projects, this speeds up the creation of documents a little.",
      xValue: 0.25,
      yValue: 0.25,
      feedbackContent:
        "The initiative to replace 3D projects with images is still a plastered process, the data transfer still being manual has a great impact on productivity and the availability of such an important document.",
    },
    {
      text: "We use a specific tool that takes advantage of 3D engineering data, this greatly speeds up the creation and updating of documents, but the collaboration of this data is still a concern.",
      xValue: 0.5,
      yValue: 0.75,
      feedbackContent:
        "The initiative to leverage Engineering data greatly streamlines the workflow, but if other contributors are unable to help create, visualize in real time, or sharing is bureaucratic, it remains a bottleneck in product development.",
    },
    {
      text: "We use a specific tool that takes advantage of 3D engineering data, which greatly speeds up the creation and updating of documents as well as allows the creation of interactive procedures, reducing the need for printed manuals. All this data is integrated into our cloud environment, allowing consumption and collaboration in a much faster and more dynamic way.",
      xValue: 0.75,
      yValue: 1,
      extension: {
        type: "MultipleChoice",
        title: "",
        items: [
          {
            text: "Layout Simulation",
            xValue: 0.01,
            yValue: 0.0,
          },
          {
            text: "Process Simulation",
            xValue: 0.01,
            yValue: 0.0,
          },
          {
            text: "Management of manufacturing bill of materials (MBOM)",
            xValue: 0.01,
            yValue: 0.0,
          },
          {
            text: "Simulation of ergonomics",
            xValue: 0.01,
            yValue: 0.0,
          },
          {
            text: "Robot programming and simulation",
            xValue: 0.01,
            yValue: 0.0,
          },
          {
            text: "Programming and simulation of machining machines",
            xValue: 0.01,
            yValue: 0.0,
          },
          {
            text: "Interactive Assembly Instructions",
            xValue: 0.01,
            yValue: 0.0,
          },
          {
            text: "Team Training using Virtual Reality",
            xValue: 0.01,
            yValue: 0.0,
          },
        ],
      },
      feedbackContent:
        "The initiative to take advantage of Engineering data, to be able to access this information at all times, to visualize and collaborate in real time, having a repository in the cloud with all these components, is the ideal path for those who want to be more productive.",
    },
  ],
  characterName: "Cristiane - Controller",
  sceneNumber: 7,
} as IQuestion;
