import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import ActionSuspense from "../../components/ActionSuspense";
import enrollmentDatabase from "../../database/EnrollmentDatabase";
import { loadEnrolments, profileState } from "../../services/Profile";
import { IEnrollment } from "../../types/EnrollmentInterface";

function EnrollmentEmpty() {
  return (
    <div className="alert alert-secondary" role="alert">
      <div style={{ padding: "50px", textAlign: "center" }}>
        You have not yet started the simulation.
        <br />
        <br />
        <NavLink to="/simulation" className="btn btn-primary">
          Start
        </NavLink>
      </div>
    </div>
  );
}

function EnrollmentRow(enrollment: IEnrollment, idx: number) {
  const progressPct = (enrollment.progress ?? 0) * 100.0;
  return (
    <div className="list-item" key={idx}>
      <p>Simulation started at {new Date(enrollment.createdAt).toString()}</p>
      <div className="progress">
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: `${progressPct}%` }}
          aria-valuenow={progressPct}
          aria-valuemin={0}
          aria-valuemax={100}
        />
      </div>
      <div
        style={{
          padding: "20px 0px",
          textAlign: "right",
        }}
      >
        {progressPct > 0 ? (
          <>
            <button
              onClick={() =>
                enrollmentDatabase
                  .reset(enrollment)
                  .then(() => window.location.reload())
              }
              className="btn btn-secondary"
            >
              Restart
            </button>
            &nbsp;
          </>
        ) : null}

        <NavLink to="/simulation" className="btn btn-primary">
          Continue &raquo;
        </NavLink>
      </div>
    </div>
  );
}

export default observer(function MyEnrollments() {
  useEffect(() => {
    loadEnrolments();
  }, []);
  return (
    <ActionSuspense
      error={profileState.enrollmentsError}
      loading={profileState.enrollmentsLoading}
    >
      {profileState.enrollments?.length > 0 ? (
        <ul>{profileState.enrollments?.map(EnrollmentRow)}</ul>
      ) : (
        <EnrollmentEmpty />
      )}
    </ActionSuspense>
  );
});
