export default function MediaProdutividadeMediaInovacao() {
  return (
    <div>
      <h1 className="display-1">Average Productivity, Average Innovation</h1>
      <p className="lead">
        Based on decisions made during the game, your company has lost some
        businesses due to difficulty in meeting all current demands of the
        market in terms of productivity. <br />
        Another point, the low investment in the innovation of its processes,
        indicated by its actions during the game, raises the possibility that
        your current costs to maintain this high productivity may be a little
        higher than they should be. <br />
        In addition, entry into new markets may be linked to innovation capacity
        and the ability to produce according to the demands of your customers.
      </p>
    </div>
  );
}
