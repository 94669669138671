import { IQuestion } from "../../types/QuestionInterface";

export default {
  questionId: "3ds_q1",
  characterName: "Fernando - CEO",
  sceneNumber: 2,
  context: [
    "Gentlemen, as you know, in recent times, we have lost important customers and we are looking for new opportunities. We need to establish a way to communicate effectively and quickly to respond to emerging opportunities.",
    "We need to establish a way to communicate effectively and quickly to respond to emerging opportunities.",
  ],
  content: ["I would like to know how we are currently doing this."],
  options: [
    {
      text: "We meet the team weekly to update opportunities.",
      xValue: 0.25,
      yValue: 0.25,
      feedbackContent:
        "The team meeting for discussions is very important, but the use of a communication tool can bring information and decisions in real time to all necessary collaborators and reduce the need to meet for updates and definitions regarding the company or project.",
    },
    {
      text: "We have a Whatsapp group that facilitates interaction and communication between the team.",
      xValue: 0.75,
      yValue: 0.5,
      feedbackContent:
        "The use of communication tools between the team, such as Whatsapp and E-mail, is already a step forward, but it would be interesting to adopt tools that are integrated with your product development process.",
    },
    {
      text: "We exchange emails with each new opportunity arising.",
      xValue: 0.5,
      yValue: 0.25,
      feedbackContent:
        "The use of communication tools between the team, such as Whatsapp and E-mail, is already a step forward, but it would be interesting to adopt tools that are integrated with your product development process.",
    },
    {
      text: "We have a dedicated tool that facilitates communication, collaboration and management of new opportunities.",
      xValue: 1,
      yValue: 1,
      extension: {
        type: "OpenAnswer",
        title: "Which tool do you use? (Write)",
      },
      feedbackContent:
        "Having good communication and team collaboration is extremely important, as it avoids doubts in carrying out the task and everyone will be aware of the project's progress.",
    },
  ],
} as IQuestion;
