export default function AltaProdutividadeAltaInovacao() {
  return (
    <div>
      <h1 className="display-1">High Productivity, High Innovation</h1>
      <p className="lead">
        Based on decisions made during the game, your company has current market
        demands in terms of productivity. <br />
        In addition, the high investment in the innovation of its processes,
        indicated by your actions during the game, indicates that your current
        costs to maintain this productivity are well dimensioned and demonstrate
        sustainability in your business. <br />
        Furthermore, entry into new markets can be linked to the innovation
        capacity of their processes.
      </p>
    </div>
  );
}
