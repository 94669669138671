export interface NavItem {
  name: string;
  onClick?: () => void;
}



export default function Nav(props: { items: NavItem[]; type?: string, selected?:number }) {
  const { items, type = "pills", selected } = props;
  const renderItem = (item: NavItem, i: number) => {
    const className = i === selected ? "nav-link active" : "nav-link";
    return (
      <li className="nav-item" key={item.name}>
        <div className={className} onClick={item.onClick}>
          {item.name}
        </div>
      </li>
    );
  };
  return <ul className={`nav nav-${type}`}>{items.map(renderItem)}</ul>;
}