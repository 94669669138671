import { makeAutoObservable } from "mobx";
import { SIMULATION_ID } from "../constants";
import enrollmentDatabase from "../database/EnrollmentDatabase";
import { allTracks } from "../database/TrackDatabase";
import { IEnrollment, IEnrollmentAnswer } from "../types/EnrollmentInterface";
import { ITrack } from "../types/TrackInterface";
import { setQuestion } from "./Question";

class EnrollmentState {
  enrollment?: IEnrollment;
  loading: boolean = false;
  error?: string;
  track?: ITrack;
  globalAverage?: number[];

  get xValue() {
    return this.enrollment ? calcEnrollmentX(this.enrollment) : 0;
  }

  get yValue() {
    return this.enrollment ? calcEnrollmentY(this.enrollment) : 0;
  }

  get currentProgress(): number {
    const l = this.track?.questions.length ?? 1;
    const a =
      this.enrollment?.answers.reduce(
        (prev, curr) =>
          prev +
          (this.track?.questions.find((q) => q === curr.questionId) ? 1 : 0),
        0.0
      ) ?? 0;
    return a / l;
  }

  startLoading() {
    this.loading = true;
    this.error = undefined;
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  setEnrollment(enrollment: IEnrollment) {
    this.loading = false;
    this.enrollment = enrollment;
    if (enrollment.trackName) {
      this.track = allTracks.find((t) => t.title === enrollment.trackName);
    }
    if (enrollment.status === "Respondendo") {
      setQuestion(this.track?.questions[0]);
    }
  }
  setError(err: unknown) {
    this.loading = false;
    this.error = String(err);
  }

  setTrack(track: ITrack) {
    this.track = track;
    this.loading = false;
    this.error = undefined;
  }

  setGlobalAverage(average?: number[]) {
    if (average) {
      this.globalAverage = average;
    }
  }

  constructor() {
    makeAutoObservable(this);
  }
}

const enrollmentState = new EnrollmentState();
export default enrollmentState;

export const ensureEnrollment = async (email?: string) => {
  let enrollment = enrollmentState.enrollment;
  if (enrollment && enrollment.simulationId === SIMULATION_ID) return;
  if (!email) return;
  enrollmentState.startLoading();
  const enrollments = await enrollmentDatabase.getUserSimulationEnrollments(
    email,
    SIMULATION_ID
  );
  enrollment =
    enrollments?.length > 0
      ? enrollments[0]
      : await enrollmentDatabase.insertEnrollment(SIMULATION_ID, email);
  enrollmentState.setEnrollment(enrollment);
};

export const selectTrack = async (track: ITrack, event: string) => {
  const { enrollment } = enrollmentState;
  enrollmentState.startLoading();
  if (!enrollment) {
    enrollmentState.setError(
      "É preciso ter uma inscrição válida para selecionar a trilha."
    );
    return;
  }
  enrollment.trackName = track.title;
  enrollment.eventName = event;
  enrollment.answers = [];
  enrollment.progress = 0;
  enrollment.updatedAt = new Date().toISOString();
  enrollment.status = "Respondendo";
  await enrollmentDatabase.updateEnrollment(enrollment);
  enrollmentState.setTrack(track);
  setQuestion(track.questions[0]);
};

export function findQuestionAnswer(
  questionId?: string
): IEnrollmentAnswer | undefined {
  return questionId
    ? enrollmentState.enrollment?.answers?.find(
        (a) => a.questionId === questionId
      )
    : undefined;
}

export function completeEnrollment() {
  const { enrollment } = enrollmentState;
  if (!enrollment) return;
  enrollmentState.setLoading(true);
  enrollment.progress = 1;
  enrollment.status = "Completo";
  enrollment.updatedAt = new Date().toISOString();
  enrollmentDatabase.updateEnrollment(enrollment);
  enrollmentState.setLoading(false);
}

export function calcEnrollmentX(enrollment: IEnrollment) {
  const l =
    allTracks.find((t) => t.title === enrollment.trackName)?.questions.length ??
    1;
  const x =
    enrollment.answers.reduce((prev, curr) => prev + (curr.xValue ?? 0), 0.0) ??
    0;
  return x / l;
}

export function calcEnrollmentY(enrollment: IEnrollment) {
  const l =
    allTracks.find((t) => t.title === enrollment.trackName)?.questions.length ??
    1;
  const y =
    enrollment.answers.reduce((prev, curr) => prev + (curr.yValue ?? 0), 0.0) ??
    0;
  return y / l;
}
