export default function AltaProdutividadeBaixaInovacao() {
  return (
    <div>
      <h1 className="display-1">High Productivity, Low Innovation</h1>
      <p className="lead">
        Based on decisions made during the game, your company has meeting
        current market demands in terms of productivity. <br />
        However, the decision not to invest in the innovation of its processes,
        indicated by your actions during the game, raises the possibility of
        that your current costs to maintain this high productivity may be way
        above what they should be. <br />
        Furthermore, entry into new markets can be linked to the innovation
        capacity of their processes.
      </p>
    </div>
  );
}
