import { observer } from "mobx-react-lite";
import { IScene } from "../../database/SceneDatabase";
import { questionState } from "../../services/Question";
import { IQuestionOption } from "../../types/QuestionInterface";
import TextPopper from "./TextPopper";

export default observer(function QuestionOption(props: {
  option: IQuestionOption;
  scene: IScene;
  index: number;
}) {
  const { option, scene, index } = props;
  const selectOption = () => {
    if (option.text === questionState.answerOption?.text) {
      questionState.releaseOption();
    } else {
      questionState.setOption(option, scene.optionsNames[index]);
    }
  };
  return (
    <TextPopper
      position={scene.optionsPos[index]}
      content={option.text}
      width="200px"
      title={scene.optionsNames[index]}
      visible={
        option.text === questionState.answerOption?.text &&
        !questionState.extensionVisible
      }
      onClick={selectOption}
    />
  );
});
