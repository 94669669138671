import { useState } from "react";
import Panels from "../../components/Panels";
import LoginWithEmailPassword from "./LoginWithEmailPassword";
import PasswordResetEmail from "./PasswordResetEmail";
import Register from "./Register";


export default function Login(props: { selected?: number }) {
    const [panel, setPanel] = useState(props.selected || 0);
    return (
        <div className="container">
            <hr />
            <h1>Log In</h1>
            <hr />
            <div className="row">
                <div className="col-sm">
                    <div>
                        <p className="lead">Welcome to the <b>3D</b>EXPERIENCE Game business simulations. Your access to the simulators is done using your username and password.</p>
                        <p className="lead">In all the simulation interfaces, we will carry out a unique follow-up in order to understand its performance and development throughout the activity.</p>
                        <p className="lead">We believe that the experience with the simulator consolidates concepts and relationships, balances soft and hard skills and prepares professionals for a changing world.</p>
                        <p className="lead">Here we can improve our decisions, we can rethink our actions, redo scenarios, test hypotheses and reassess.</p>
                        <p className="lead">Protagonism and accountability are essential, opinions are marked by plurality, the environment is dynamic and full of opportunities.</p>
                        <p className="lead">Take advantage of this challenge and when in doubt, write to{" "} <a href="mailto:suporte@compsim.com.br">suporte@compsim.com.br</a>. </p>

                    </div>
                </div>
                <div className="col-sm">
                    <div>
                        <Panels panels={[
                            {
                                name: "I have an account",
                                content: <LoginWithEmailPassword onRegister={() => setPanel(1)} onForgotPassword={() => setPanel(2)} />
                            },
                            {
                                name: "I need an account",
                                content: <Register />
                            }
                            ,
                            {
                                name: "I lost my password",
                                content: <PasswordResetEmail />,
                                visible: false
                            }
                        ]}
                            selected={panel}
                            onSelect={setPanel} />

                    </div>
                </div>
            </div>
        </div>
    );
}