export interface IScenePosition {
  X: number;
  Y: number;
}
export interface IScene {
  questionBackground: string;
  optionsBackground: string;
  answerBackground: string;
  questionPos: IScenePosition;
  optionsPos: IScenePosition[];
  questionDirection?: string;
  answerPos: IScenePosition[];
  optionsNames: string[];
}

export const Scene1: IScene = {
  questionBackground: "/img/scenes/scene03_rh_01a.png",
  optionsBackground: "/img/scenes/scene03_rh_01b.png",
  answerBackground: "/img/scenes/scene03_rh_01c.png",
  questionPos: {
    X: 525,
    Y: 600,
  },
  optionsPos: [
    { X: 644, Y: 460 },
    { X: 900, Y: 405 },
    { X: 1150, Y: 405 },
    { X: 1430, Y: 505 },
  ],
  answerPos: [
    { X: 1015, Y: 630 },
    { X: 1188, Y: 595 },
    { X: 1367, Y: 595 },
    { X: 1558, Y: 665 },
  ],
  optionsNames: ["Rodrigo", "Luiza", "Verônica", "João"],
};

export const Scene2: IScene = {
  questionBackground: "/img/scenes/scene02_dircom_01a.png",
  optionsBackground: "/img/scenes/scene02_dircom_01b.png",
  answerBackground: "/img/scenes/scene02_dircom_01c.png",
  questionPos: {
    X: 1414,
    Y: 744,
  },
  optionsPos: [
    { X: 491, Y: 462 },
    { X: 715, Y: 735 },
    { X: 996, Y: 525 },
    { X: 1341, Y: 715 },
  ],
  answerPos: [
    { X: 338, Y: 640 },
    { X: 474, Y: 835 },
    { X: 667, Y: 690 },
    { X: 833, Y: 810 },
  ],
  optionsNames: ["Luiza", "Rodrigo", "João", "Verônica"],
};

export const Scene3: IScene = {
  questionBackground: "/img/scenes/scene03_ceo_01a.png",
  optionsBackground: "/img/scenes/scene03_ceo_01b.png",
  answerBackground: "/img/scenes/scene03_ceo_01c.png",
  questionPos: {
    X: 1135,
    Y: 880,
  },
  questionDirection: "right",
  optionsPos: [
    { X: 355, Y: 520 },
    { X: 637, Y: 555 },
    { X: 1500, Y: 575 },
    { X: 1158, Y: 515 },
  ],
  answerPos: [
    { X: 1297, Y: 550 },
    { X: 1151, Y: 580 },
    { X: 629, Y: 570 },
    { X: 814, Y: 550 },
  ],
  optionsNames: ["Luiza", "Rodrigo", "João", "Verônica"],
};

export const Scene4: IScene = {
  questionBackground: "/img/scenes/scene01_factory_rh01a.png",
  optionsBackground: "/img/scenes/scene01_factory_rh01b.png",
  answerBackground: "/img/scenes/scene01_factory_rh01c.png",
  questionPos: {
    X: 400,
    Y: 570,
  },
  optionsPos: [
    { X: 470, Y: 320 },
    { X: 727, Y: 320 },
    { X: 1300, Y: 350 },
    { X: 1048, Y: 350 },
  ],
  answerPos: [
    { X: 960, Y: 595 },
    { X: 1128, Y: 590 },
    { X: 1495, Y: 605 },
    { X: 1320, Y: 618 },
  ],
  optionsNames: ["Luiza", "Verônica", "Rodrigo", "João"],
};

export const Scene5: IScene = {
  questionBackground: "/img/scenes/scene05_office_project01a.png",
  optionsBackground: "/img/scenes/scene05_office_project01b.png",
  answerBackground: "/img/scenes/scene05_office_project01c.png",
  questionPos: {
    X: 1445,
    Y: 645,
  },
  optionsPos: [
    { X: 1365, Y: 490 },
    { X: 545, Y: 490 },
    { X: 750, Y: 500 },
    { X: 748, Y: 490 },
  ],
  answerPos: [
    { X: 790, Y: 580 },
    { X: 340, Y: 575 },
    { X: 480, Y: 590 },
    { X: 400, Y: 618 },
  ],
  optionsNames: ["Luiza", "Verônica", "Rodrigo", "João"],
};

export const Scene6: IScene = {
  questionBackground: "/img/scenes/scene04_office_eng01a.png",
  optionsBackground: "/img/scenes/scene04_office_eng01b.png",
  answerBackground: "/img/scenes/scene04_office_eng01c.png",
  questionPos: {
    X: 1560,
    Y: 760,
  },
  optionsPos: [
    { X: 580, Y: 430 },
    { X: 345, Y: 650 },
    { X: 1360, Y: 680 },
    { X: 970, Y: 490 },
  ],
  answerPos: [
    { X: 300, Y: 590 },
    { X: 150, Y: 735 },
    { X: 705, Y: 750 },
    { X: 510, Y: 638 },
  ],
  optionsNames: ["Luiza", "Verônica", "Rodrigo", "João"],
};

export const Scene7: IScene = {
  questionBackground: "/img/scenes/scene04_factory_eng01a.png",
  optionsBackground: "/img/scenes/scene04_factory_eng01b.png",
  answerBackground: "/img/scenes/scene04_factory_eng01c.png",
  questionPos: {
    X: 170,
    Y: 760,
  },
  optionsPos: [
    { X: 1120, Y: 470 },
    { X: 810, Y: 460 },
    { X: 470, Y: 495 },
    { X: 1390, Y: 520 },
  ],
  answerPos: [
    { X: 1430, Y: 570 },
    { X: 1265, Y: 570 },
    { X: 1120, Y: 585 },
    { X: 1590, Y: 598 },
  ],
  optionsNames: ["Luiza", "Verônica", "Rodrigo", "João"],
};

export const Scene8: IScene = {
  questionBackground: "/img/scenes/scene06_factory_plan01a.png",
  optionsBackground: "/img/scenes/scene06_factory_plan01b.png",
  answerBackground: "/img/scenes/scene06_factory_plan01c.png",
  questionPos: {
    X: 1385,
    Y: 515,
  },
  optionsPos: [
    { X: 1315, Y: 565 },
    { X: 470, Y: 525 },
    { X: 685, Y: 560 },
    { X: 1035, Y: 445 },
  ],
  answerPos: [
    { X: 980, Y: 580 },
    { X: 480, Y: 575 },
    { X: 625, Y: 595 },
    { X: 830, Y: 520 },
  ],
  optionsNames: ["Luiza", "Verônica", "Rodrigo", "João"],
};

export const Scene9: IScene = {
  questionBackground: "/img/scenes/scene07_office_sust01a.png",
  optionsBackground: "/img/scenes/scene07_office_sust01b.png",
  answerBackground: "/img/scenes/scene07_office_sust01c.png",
  questionPos: {
    X: 355,
    Y: 685,
  },
  optionsPos: [
    { X: 1440, Y: 540 },
    { X: 525, Y: 525 },
    { X: 1150, Y: 560 },
    { X: 830, Y: 565 },
  ],
  answerPos: [
    { X: 1565, Y: 680 },
    { X: 998, Y: 680 },
    { X: 1365, Y: 695 },
    { X: 1190, Y: 700 },
  ],
  optionsNames: ["Luiza", "Verônica", "Rodrigo", "João"],
};

export const Scene10: IScene = {
  questionBackground: "/img/scenes/scene08_factory_sales01a.png",
  optionsBackground: "/img/scenes/scene08_factory_sales01b.png",
  answerBackground: "/img/scenes/scene08_factory_sales01c.png",
  questionPos: {
    X: 1440,
    Y: 595,
  },
  optionsPos: [
    { X: 755, Y: 410 },
    { X: 1060, Y: 530 },
    { X: 490, Y: 590 },
    { X: 1250, Y: 440 },
  ],
  answerPos: [
    { X: 445, Y: 570 },
    { X: 575, Y: 625 },
    { X: 295, Y: 660 },
    { X: 735, Y: 570 },
  ],
  optionsNames: ["Luiza", "Verônica", "Rodrigo", "João"],
};

export const Scene11: IScene = {
  questionBackground: "/img/scenes/scene09_factory_ti01a.png",
  optionsBackground: "/img/scenes/scene09_factory_ti01b.png",
  answerBackground: "/img/scenes/scene09_factory_ti01c.png",
  questionPos: {
    X: 1405,
    Y: 430,
  },
  optionsPos: [
    { X: 750, Y: 435 },
    { X: 1030, Y: 415 },
    { X: 510, Y: 450 },
    { X: 1250, Y: 445 },
  ],
  answerPos: [
    { X: 495, Y: 530 },
    { X: 645, Y: 515 },
    { X: 335, Y: 540 },
    { X: 785, Y: 535 },
  ],
  optionsNames: ["Luiza", "Verônica", "Rodrigo", "João"],
};

export const Scene12: IScene = {
  questionBackground: "/img/scenes/scene10_office_oper01a.png",
  optionsBackground: "/img/scenes/scene10_office_oper01b.png",
  answerBackground: "/img/scenes/scene10_office_oper01c.png",
  questionPos: {
    X: 1340,
    Y: 595,
  },
  optionsPos: [
    { X: 1140, Y: 415 },
    { X: 635, Y: 425 },
    { X: 1410, Y: 425 },
    { X: 880, Y: 440 },
  ],
  answerPos: [
    { X: 740, Y: 590 },
    { X: 415, Y: 600 },
    { X: 880, Y: 590 },
    { X: 575, Y: 615 },
  ],
  optionsNames: ["Luiza", "Verônica", "Rodrigo", "João"],
};

export const SCENES = [
  Scene1,
  Scene2,
  Scene3,
  Scene4,
  Scene5,
  Scene6,
  Scene7,
  Scene8,
  Scene9,
  Scene10,
  Scene11,
  Scene12,
];
