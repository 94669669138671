import { NavLink } from "react-router-dom";

export default function ReportsHome() {
  return (
    <div className="container">
      <h1 className="display-1">Reports</h1>
      <div className="list-group">
        <NavLink
          to="./enrollments"
          className="list-group-item list-group-item-action"
        >
          Enrollments
        </NavLink>
        <NavLink
          to="./questions"
          className="list-group-item list-group-item-action"
        >
          Answer distribution
        </NavLink>
        <NavLink
          to="./possible-answer-distribution"
          className="list-group-item list-group-item-action"
        >
          Answer probability distribution
        </NavLink>
      </div>
    </div>
  );
}
