export default function MediaProdutividadeAltaInovacao() {
  return (
    <div>
      <h1 className="display-1">Average Productivity, High Innovation</h1>
      <p className="lead">
        Based on decisions made during the game, your company has lost some
        businesses due to difficulty in meeting all current demands of the
        market in terms of productivity. <br />
        However, the high investment in innovation of its processes, indicated
        by its actions during the game, indicates that your current costs to
        maintain this productivity are well scaled and demonstrate
        sustainability in their businesses. <br />
        In addition, entry into new markets may be linked to innovation capacity
        and the ability to produce according to the demands of its customers.
      </p>
    </div>
  );
}
