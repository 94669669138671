export default function BaixaProdutividadeBaixaInovacao() {
  return (
    <div>
      <h1 className="display-1">Low Productivity, Low Innovation</h1>
      <p className="lead">
        Based on decisions made during the game, your company has lost several
        businesses due to difficulty in meeting all the current demands of the
        market in terms of productivity. <br />
        Another point is that the decision to not investing in the innovation of
        its processes, indicated by its actions during play, raises the
        possibility that your current costs for maintain low productivity may be
        well above what they should. <br />
        In addition, entry into new markets may be linked to capacity innovation
        and the ability to produce according to the demands of its customers.
      </p>
    </div>
  );
}
