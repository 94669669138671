import { observer } from "mobx-react-lite";
import { ReactElement } from "react";
import ActionSuspense from "../../components/ActionSuspense";
import AppLayout from "../../components/AppLayout";
import { authenticationState } from "../../services/Authentication";
import Login from "./Login";
import VerifyEmail from "./VerifyEmail";

const AuthContent = () => authenticationState.isLoggedIn ? <VerifyEmail /> : <Login />

const AuthenticationWall = observer((props: { children: ReactElement }) => {
    if (authenticationState.loggedInAndVerified === true) return props.children;
    else return (
        <AppLayout justifyContent="flex-start">
            <ActionSuspense loading={authenticationState.loading} error={authenticationState.error}>
                <AuthContent />
            </ActionSuspense>
        </AppLayout>
    );
});
export default AuthenticationWall;