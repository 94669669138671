export default function BaixaProdutividadeAltaInovacao() {
  return (
    <div>
      <h1 className="display-1">Low Productivity, High Innovation</h1>
      <p className="lead">
        Based on decisions made during the game, your company has lost several
        businesses due to difficulty in meeting all the current demands of the
        market in terms of productivity. <br />
        However, the high investment in the innovation of its processes,
        indicated by your actions during the game, indicates that your current
        costs to maintain this productivity are well dimensioned and demonstrate
        sustainability in your business. <br />
        In addition, entry into new markets may be linked to innovation capacity
        and the ability to produce according to the demands of its customers.
      </p>
    </div>
  );
}
