import { getNineBoxQuadrant } from "../../../services/NineBoxAnalysis";
import AltaProdutividadeAltaInovacao from "./alta_produtividade_alta_inovacao";
import AltaProdutividadeBaixaInovacao from "./alta_produtividade_baixa_inovacao";
import AltaProdutividadeMediaInovacao from "./alta_produtividade_media_inovacao";
import BaixaProdutividadeAltaInovacao from "./baixa_produtividade_alta_inovacao";
import BaixaProdutividadeBaixaInovacao from "./baixa_produtividade_baixa_inovacao";
import BaixaProdutividadeMediaInovacao from "./baixa_produtividade_media_inovacao";
import MediaProdutividadeAltaInovacao from "./media_produtividade_alta_inovacao";
import MediaProdutividadeBaixaInovacao from "./media_produtividade_baixa_inovacao";
import MediaProdutividadeMediaInovacao from "./media_produtividade_media_inovacao";

export default function NineboxDescription(props: { point: number[] }) {
  const { point } = props;
  const nbName = getNineBoxQuadrant(point[0], point[1])?.name ?? "";
  switch (nbName) {
    case "Baixa Produtividade, Baixa Inovação":
      return <BaixaProdutividadeBaixaInovacao />;
    case "Média Produtividade, Baixa Inovação":
      return <MediaProdutividadeBaixaInovacao />;
    case "Alta Produtividade, Baixa Inovação":
      return <AltaProdutividadeBaixaInovacao />;

    case "Baixa Produtividade, Média Inovação":
      return <BaixaProdutividadeMediaInovacao />;
    case "Média Produtividade, Média Inovação":
      return <MediaProdutividadeMediaInovacao />;
    case "Alta Produtividade, Média Inovação":
      return <AltaProdutividadeMediaInovacao />;

    case "Baixa Produtividade, Alta Inovação":
      return <BaixaProdutividadeAltaInovacao />;
    case "Média Produtividade, Alta Inovação":
      return <MediaProdutividadeAltaInovacao />;
    case "Alta Produtividade, Alta Inovação":
      return <AltaProdutividadeAltaInovacao />;

    default:
      return <div>We could not found a quadrant to describe.</div>;
  }
}
