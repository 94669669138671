import { IQuestion } from "../../types/QuestionInterface";

export default {
  questionId: "3ds_q6",
  context: [
    "The commercial team is projecting a 20% growth in order demand within 3 months.",
  ],
  content: [
    "How can we guarantee that we will be ready to absorb this growth in such a short period of time?",
    "Can we assess whether there is manpower, inputs and resources available?",
  ],
  options: [
    {
      text: "We can do an analysis, but it will take a few days to complete and present a result for discussion.",
      xValue: 0,
      yValue: 0,
      feedbackContent:
        "To predict possible market growth, one cannot depend on analysis that take time to complete, this can lead to a gap in relation to emerging trends, as companies that obtain real-time information on demand, market direction and new products that arrive, they end up taking the lead in the development and preparation of their team.",
    },
    {
      text: "Yes, our ERP helps us in projecting the purchase of inputs, but we will have to simulate the capacity of the workforce and machinery in auxiliary spreadsheets to analyze whether we will have overloads.",
      xValue: 0.35,
      yValue: 0.35,
      feedbackContent:
        "To predict possible market growth, the help of an ERP means a big step, but it does not fill all the gaps to have a complete overview. Adhering to a solution that makes it possible to create several possible scenarios can prepare the entire team to withstand eventual peaks in demand, without any surprises.",
    },
    {
      text: "Yes, we have a solution that allows us to simulate different growth scenarios by projecting the usage of each required resource.",
      xValue: 0.8,
      yValue: 0.8,
      feedbackContent:
        "Predicting and simulating situations of demand growth is essential for a company, being aware of trends is the result of tools that provide the correct information in order to use resources in the best way.",
    },
  ],
  backgroundImage: "/img/cafeteria/bg-sala.jpg",
  characterName: "Antonio - Planning Manager",
  sceneNumber: 1,
} as IQuestion;
