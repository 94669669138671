/*
    Trims and remove quotes from a name.
*/
export const removeQuotes = (line: string): string => {
  line = line.trim();
  const char0 = line.charAt(0);
  if (char0 === '"' || char0 === "'") {
    line = line.substring(1, line.length - 1).trim();
  }
  return line;
};

export const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const extractEmail = (line: string): string | undefined => {
  let newInput = line.toLowerCase().trim();
  if (
    newInput.endsWith(",") ||
    newInput.endsWith(";") ||
    newInput.endsWith(".")
  )
    newInput = newInput.substring(0, newInput.length - 1);
  return EMAIL_PATTERN.test(newInput) ? newInput : undefined;
};

export const capitalizeWord = (line: string): string => {
  return line.length < 4
    ? line
    : line.charAt(0).toUpperCase() + line.substring(1).toLowerCase();
};

export const capitalize = (line: string): string =>
  line.split(" ").map(capitalizeWord).join(" ");

export const extractNameFromEmail = (email?: string | null): string => {
  if (!email) return "Visitante";
  const lpos = email.indexOf("@");
  const name = lpos > 0 ? email.substring(0, lpos) : email;
  return capitalize(
    name
      .replaceAll("_", "")
      .replaceAll(".", " ")
      .replaceAll("+", " ")
      .replaceAll("|", " ")
      .trim()
  );
};

export const parseNameEmail = (
  line: string
): { name?: string; email?: string } => {
  const leftSign = line.indexOf("<");
  const rightSign = line.indexOf(">", leftSign + 1);
  if (leftSign >= 0 && rightSign >= 0) {
    return {
      name: removeQuotes(line.substring(0, leftSign)),
      email: extractEmail(line.substring(leftSign + 1, rightSign)),
    };
  } else {
    const email = extractEmail(line);
    return { name: extractNameFromEmail(email ?? ""), email };
  }
};

export const parseNameValidEmails = (
  content: string
): { name?: string; email?: string }[] => {
  return content
    .split(/\r?\n/)
    .map(parseNameEmail)
    .filter((i) => i.email);
};

export const formatPercentage = (v?: string | number | null, digits = 1) => {
  return v === null || v === undefined
    ? "-"
    : (100.0 * Number(v)).toFixed(digits) + " %";
};
