import { observer } from "mobx-react-lite";
import ActionSuspense from "../../components/ActionSuspense";
import {
  authenticationState,
  resendVerificationEmail,
} from "../../services/Authentication";

export default observer(function VerifyEmail() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm">
          <div>
            <h1 style={{ marginBottom: "20px" }}>Welcome!</h1>
          </div>
        </div>
        <div className="col-sm">
          <p>You still need to verify your email address.</p>
          <p>Go to your inbox and click on the validation link.</p>
          <p>Did not receive the link?</p>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => window.location.reload()}
          >
            Refresh
          </button>
          &nbsp;
          <button
            type="button"
            className="btn btn-secondary"
            onClick={resendVerificationEmail}
          >
            Resend
          </button>
          <ActionSuspense
            loading={authenticationState.authLoading}
            error={authenticationState.authError}
            success={authenticationState.authSuccess}
          />
        </div>
      </div>
    </div>
  );
});
