import { doc, setDoc, where } from "firebase/firestore";
import { firebaseQuery, firestore } from "../Firebase";
export const PROFILE_COLLECTION = "profile";
export interface IProfile {
  id: string;
  role: string;
  email: string;
  name: string;
  phone: string;
  company: string;
  position: string;
  zipCode: string;
  city: string;
  country: string;
  dataProcessAuthorized: string;
  marketingAuthorized: string;
}

class ProfileDatabase {
  async setProfile(profile: IProfile) {
    return setDoc(doc(firestore, PROFILE_COLLECTION, profile.id), profile);
  }
  async getProfile(email: string): Promise<IProfile | undefined> {
    const profiles = await firebaseQuery(
      PROFILE_COLLECTION,
      where("email", "==", email)
    );
    return profiles && profiles.length > 0
      ? (profiles[profiles.length - 1] as IProfile)
      : undefined;
  }
}

const profileDatabase = new ProfileDatabase();
export default profileDatabase;
