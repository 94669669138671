export default function MediaProdutividadeBaixaInovacao() {
  return (
    <div>
      <h1 className="display-1">Average Productivity, Low Innovation</h1>
      <p className="lead">
        Based on decisions made during the game, your company has lost some
        businesses due to difficulties in meeting all the current demands of the
        market in terms of productivity. <br />
        Another point is that the decision not to invest in the innovation of
        its processes, indicated by its actions during the game, raises the
        possibility that your current costs to maintain this high productivity
        may be well above what they should be. <br />
        In addition, entry into new markets may be linked to innovation capacity
        and the ability to produce according to the demands of your customers.
      </p>
    </div>
  );
}
