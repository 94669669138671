import { ReactNode } from "react";
import "./AppLayout.css";
import AppNav from "./AppNav";

export default function AppLayout(props: {
    children: ReactNode,
    backgroundImg?: string;
    flexDirection?: ("row" | "column");
    justifyContent?: string;
    alignItems?: string;
    gap?: string;
}) {
    return <div className="app-layout" style={{ backgroundImage: `url("${props.backgroundImg}")` }}>
        <AppNav />
        <div className="app-content" style={{
            flexDirection: props.flexDirection,
            justifyContent: props.justifyContent,
            alignItems: props.alignItems
        }}>
            {props.children}
        </div>
    </div>
}