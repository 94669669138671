import { IQuestion } from "../../types/QuestionInterface";

export default {
  questionId: "3ds_q8",
  context: [
    "We were able to close the contract with a US customer that brings a new product that will require a new layout of our production line, tooling manufacturing,",
    "las well as training of the production and assembly team.",
  ],
  content: [
    "We will have to be agile to implement these changes in our factory. How do we usually make these adjustments to introduce new production processes?",
  ],
  options: [
    {
      text: "We keep the current layout because we have always made our products that way.",
      xValue: 0.25,
      yValue: 0,
      feedbackContent:
        "For the implementation of new production processes, it is important to always have some flexibility and try to maintain a layout that is easy to adapt in case there is a business opportunity that cannot be missed. Keeping the single and standard layout may not be functional, as there will be conflicts and mainly you will need a longer deadline for delivery for a planning detail.",
    },
    {
      text: "We do not have solutions for simulating these scenarios and we usually carry out the simulations and adjustments during the actual production in the factory.",
      xValue: 0.25,
      yValue: 0.5,
      feedbackContent:
        "For the implementation of new production processes, making adjustments during operation and production can be extremely risky, as planning is essential to mitigate risks and set deadlines. Hiring a consultancy for each necessary change can be very costly in the long run and there are already tools capable of automatically building the best layout for such an activity.",
    },
    {
      text: "We can hire a consulting company to support us in the simulations and definitions of the best scenario for the production of this new product, but this cost was not considered in the proposal.",
      xValue: 0.5,
      yValue: 0.5,
      feedbackContent:
        "For the implementation of new production processes, outsourcing costs can become a good alternative, but with a layout management tool, the response time to customers and planning becomes faster, and the company would be more competitive as it will be able to negotiate a value attractive without having to pay for consulting in advance.",
    },
    {
      text: "We have a solution that allows us to simulate different scenarios of the plant and arrive at the best layout, process and suitability of the manufacturing and assembly team before starting production.",
      xValue: 0.75,
      yValue: 1,
      extension: {
        type: "MultipleChoice",
        title: "",
        items: [
          {
            text: "Layout Simulation",
            xValue: 0.01,
            yValue: 0.0,
          },
          {
            text: "Process Simulation",
            xValue: 0.01,
            yValue: 0.0,
          },
          {
            text: "Management of manufacturing bill of materials (MBOM)",
            xValue: 0.01,
            yValue: 0.0,
          },
          {
            text: "Simulation of ergonomics",
            xValue: 0.01,
            yValue: 0.0,
          },
          {
            text: "Robot programming and simulation",
            xValue: 0.01,
            yValue: 0.0,
          },
          {
            text: "Programming and simulation of machining machines",
            xValue: 0.01,
            yValue: 0.0,
          },
          {
            text: "Interactive Assembly Instructions",
            xValue: 0.01,
            yValue: 0.0,
          },
          {
            text: "Team Training using Virtual Reality",
            xValue: 0.01,
            yValue: 0.0,
          },
        ],
      },
      feedbackContent:
        "For the implementation of new production processes, it is always good to have a tool that automates and predicts the best layout among the available resources, relieving planning time, streamlining the entire process of adapting to the new product.",
    },
  ],
  backgroundImage: "/img/cafeteria/bg-conselho.png",
  characterName: "Renata - Sales Director",
} as IQuestion;
