import enrollmentState from "../services/Enrollment";
import { NINE_BOX } from "../services/NineBoxAnalysis";
import Echart from "./Echart";

function translateName(name: string) {
  switch (name) {
    case "Baixa Produtividade, Baixa Inovação":
      return "Low Productivity, Low Innovation";
    case "Média Produtividade, Baixa Inovação":
      return "Average Productivity, Low Innovation";
    case "Alta Produtividade, Baixa Inovação":
      return "High Productivity, Low Innovation";
    case "Baixa Produtividade, Média Inovação":
      return "Low Productivity, Average Innovation";
    case "Média Produtividade, Média Inovação":
      return "Average Productivity, Average Innovation";
    case "Alta Produtividade, Média Inovação":
      return "High Productivity, Average Innovation";
    case "Baixa Produtividade, Alta Inovação":
      return "Low Productivity, High Innovation";
    case "Média Produtividade, Alta Inovação":
      return "Average Productivity, High Innovation";
    case "Alta Produtividade, Alta Inovação":
      return "High Productivity, High Innovation";
    default:
      return name;
  }
}

const mark_areas: any[] = NINE_BOX.map((nb) => ({
  type: "scatter",
  name: translateName(nb.name),
  markArea: {
    itemStyle: {
      color: nb.color,
    },
    label: {
      position: [10, 10],
    },
    data: [
      [
        {
          name: translateName(nb.name),
          coord: [nb.xMin, nb.yMin],
        },
        {
          coord: [nb.xMax, nb.yMax],
        },
      ],
    ],
  },
}));

export default function NineBox(props: {
  points: number[][];
  autoAverage?: boolean;
  legendText?: string;
}) {
  const { points, autoAverage, legendText = "Você" } = props;
  const series = [...mark_areas];
  if (autoAverage === true) {
    console.error("Please implement auto average");
  }
  series.push({
    name: "You",
    symbolSize: 20,
    data: points,
    type: "scatter",
  });
  if (enrollmentState.globalAverage && series.length === 10) {
    console.log([...enrollmentState.globalAverage]);
    series.push({
      name: "Global mean",
      symbolSize: 20,
      data: [enrollmentState.globalAverage],
      type: "scatter",
    });
  }
  return (
    <Echart
      height="500px"
      options={{
        legend: {
          data: [legendText, "Global mean"],
        },
        grid: {
          left: "20",
          right: "70",
          bottom: "10",
          containLabel: true,
        },
        xAxis: {
          name: "Innovation",
          position: "bottom",
          max: 1.0,
        },
        yAxis: {
          name: "Productivity",
          max: 1.0,
        },
        series: series,
      }}
    />
  );
}
