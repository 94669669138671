import { IQuestion } from "../../types/QuestionInterface";

export default {
  questionId: "3ds_q3",
  context: [
    "Our best designer is moving to the Northeast and I know we can't lose him because he is vital to the day to day of our company.",
    "As you know, our remote work policy is already applied to some areas, but we never had people from the engineering team in this situation.",
  ],
  content: [
    "How can we keep him on the team even remotely?",
  ],
  options: [
    {
      text: "Unfortunately, it will be unfeasible since our project files are stored on a local infrastructure that does not allow remote access.",
      xValue: 0,
      yValue: 0,
      feedbackContent:
        "About remote work, technology over the years has brought new opportunities and different ways of thinking about the subject. Losing such an important employee who has good results can affect the company's productivity.",
    },
    {
      text: "We can put it in a project that has no collaboration with anyone on the team and we use a file sharing tool (ex: WeTransfer, Google Drive...).",
      xValue: 0.25,
      yValue: 0.5,
      feedbackContent:
        "Collaboration between teams and management is key to achieving goals and having a productive workflow. And having unlinked communication and file sharing tools with your PLM like WeTransfer, Google Drive can lead to future conflicts.",
    },
    {
      text: "The only way to do this currently is to work via VPN, to access the server.",
      xValue: 0.5,
      yValue: 0.25,
      feedbackContent:
        "Working via VPN, accessing the server remotely, can be a good idea initially, but in the long run the big interruptions and connection slowness can end up leaving the workflow not fluid.",
    },
    {
      text: "We use a cloud collaboration platform and this solves the challenge of having our designer remotely.",
      xValue: 0.75,
      yValue: 0.75,
      extension: {
        type: "OpenAnswer",
        title: "Which platform do you use?",
      },
      feedbackContent:
        "By making a cloud collaboration platform available to your designer, it enables him to be anywhere in the world, use up-to-date and real-time documents and drawings, making him continue to deliver great work as well as in person.",
    },
  ],
  backgroundImage: "/img/cafeteria/bg-elevator.jpg",
  characterName: "Leonor - HR Manager",
  sceneNumber: 8,
} as IQuestion;
