import { SCENES } from "../../database/SceneDatabase";

export default function Preload() {
  const allImages: string[] = [];
  SCENES.forEach((s) => {
    allImages.push(s.questionBackground);
    allImages.push(s.optionsBackground);
    allImages.push(s.answerBackground);
  });

  const imgset: string[] = Array.from(new Set(allImages));
  return (
    <div style={{ height: "0px", overflow: "hidden" }}>
      {imgset.map((img, i) => (
        <img src={img} key={i} alt="" />
      ))}
    </div>
  );
}
