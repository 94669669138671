import { IQuestion } from "../../types/QuestionInterface";

export default {
  questionId: "3ds_q4",
  context: [
    "We have a challenging issue! Our engineering service providers work with different 3D CAD Software.",
    "However, we need all items of each project to be in the same environment, thus forming a single product.",
  ],
  content: ["How are you currently facing this situation?"],
  options: [
    {
      text: "Our suppliers only send the designs in 2D PDF",
      xValue: 0.25,
      yValue: 0,
      feedbackContent:
        "About managing supplier items, working with shared 2D PDFs end up restricting collaboration and real-time visualization of the project's progress.",
    },
    {
      text: "Currently I require my vendors to adapt and work with the same software as I do.",
      xValue: 0.75,
      yValue: 0.25,
      feedbackContent:
        "Segregating providers that work only with the same software may not be the most viable option, as there are currently other forms of file sharing that use the native format.",
    },
    {
      text: "For suppliers that do not follow the same standard, we ask them to send their files in generic 3D formats (STEP, IGES and etc...). It's not ideal, but it's working!",
      xValue: 0.5,
      yValue: 0.25,
      feedbackContent:
        "To deal with suppliers that do not follow the same standard, it is essential to extract as much information as possible, but STEP files lose characteristics when sharing 3D CAD, such as lighting and camera settings.",
    },
    {
      text: "In our process, we are able to receive these files in their native formats and use them to virtually create the final product, without loss of information.",
      xValue: 1,
      yValue: 1,
      extension: {
        type: "OpenAnswer",
        title: "What CAD system do you use for this?",
      },
      feedbackContent:
        "The composition of a product does not always come from parts designed in just one CAD Software, and working with a tool that allows you to use all types of extensions in their native form will certainly open up a range of suppliers and optimize project time, so consequently becomes the best option.",
    },
  ],
  backgroundImage: "/img/cafeteria/bg-conselho.png",
  characterName: "Melissa - Engineering Manager",
  sceneNumber: 5,
} as IQuestion;
