import { IQuestion } from "../../types/QuestionInterface";

export default {
  questionId: "3ds_q9",
  context: [],
  content: [
    "Hi! I'm starting a project to redefine our infrastructure and I would like to know if the software you use necessarily needs to be installed on our physical servers or if we could consider using these cloud solutions.",
  ],
  options: [
    {
      text: "The solutions we use do not have cloud support, so I don't think I can help you with this initiative.",
      xValue: 0,
      yValue: 0,
      feedbackContent:
        "Cloud solutions have become essential lately, and the trend is for the market to adopt more and more tools like this. Without the need for investments in very powerful hardware and local servers, you still guarantee an environment that is fully available at all times and 100% secure.",
    },
    {
      text: "We currently use our local servers to run our main systems and store projects and we would not like to change that.",
      xValue: 0.0,
      yValue: 0.0,
      feedbackContent:
        "Cloud solutions have become essential lately, and the trend is for the market to adopt more and more tools like this. Without the need for investments in very powerful hardware and local servers, you still guarantee an environment that is fully available at all times and 100% secure.",
    },
    {
      text: "The solutions we use have cloud support but I don't have a defined plan for this migration.",
      xValue: 0.25,
      yValue: 0.75,
      feedbackContent:
        "Performing the migration to the Cloud as soon as possible will be a very important step. This change will result in much more team collaboration and, among other areas, clean communication and real-time information available at all times.",
    },
    {
      text: "Our tools are able to run in the cloud and our team is open to start a migration process, please consider our department favorable to your project!",
      xValue: 0.25,
      yValue: 1,
      feedbackContent:
        "Companies prepared with cloud tools are ahead of the competition, as they deliver something totally new, a different, but very functional and agile way of working.",
    },
  ],
  backgroundImage: "/img/cafeteria/bg-scene-gray-room.jpg",
  characterName: "Felipe - IT Director",
  sceneNumber: 10,
} as IQuestion;
