import { IQuestion } from "../../types/QuestionInterface";

export default {
  questionId: "3ds_q2",
  characterName: "Renata - Commercial director",
  sceneNumber: 3,
  context: [
    "An opportunity arose at a client in Europe that requires us to verify how we do it in order to have real-time visibility into the evolution of project management.",
  ],
  content: ["How can we meet this requirement?"],
  options: [
    {
      text: "In our work policy, we look for the scope of the project, we create the product and at the end of the work we send it to the client for final approval, if there is any adjustment, we can do it.",
      xValue: 0.25,
      yValue: 0.25,
      feedbackContent:
        "For the customer to have real-time visibility, it is crucial that there is interaction with him throughout the product creation and development process, validation and communication needs to be constant in order to avoid rework.",
    },
    {
      text: "In real time we can not do, however, we can periodically send via email the status of the project.",
      xValue: 0.5,
      yValue: 0.25,
      feedbackContent:
        "For the customer to have real-time visibility, it is crucial that there is interaction with him throughout the product creation and development process, validation and communication needs to be constant in order to avoid rework.",
    },
    {
      text: "We can talk to the IT area to give the client access to our server and release a folder with the project's PDFs.",
      xValue: 0.75,
      yValue: 0.5,
      feedbackContent:
        "For the customer to have visibility, giving access to a folder on the server is a valid alternative, but still considered plastered, real-time visibility increases assertiveness and customer requests will be answered faster, such as changes in the project.",
    },
    {
      text: "We will give access to our project management system that is in the cloud and from there the customer will have a real-time view of their project.",
      xValue: 1,
      yValue: 1,
      extension: {
        type: "OpenAnswer",
        title: "Which tool do you use? (Write)",
      },
      feedbackContent:
        "Giving the customer access to the cloud management system becomes the option that manages to achieve an immense level of satisfaction, being accessible by everyone and at all times, enabling project visualization and instant feedback.",
    },
  ],
} as IQuestion;
