import AppLayout from "../../components/AppLayout";

export default function About() {
    return (
        <AppLayout justifyContent="flex-start" alignItems="flex-start">
            <div className="container">
                <hr />
                <h1>About the <b>3D</b>EXPERIENCE Game</h1>
                <hr />
                <p className="lead">
                O <b>3D</b>EXPERIENCE Game aims to help players understand the current
                 scenario in which they find themselves in their daily lives within their company, through
                 of a recreational activity.
                 Through the <b>3D</b>EXPERIENCE Game, the player will have a macro analysis of how his company
                 is in terms of Productivity and Innovation and the complete diagnosis will help
                 the DASSAULT SYSTEMES team and partners to outline points for improvement and how
                 enhance even more the qualities that already exist in the company.
                 Good game!
                </p>
                <hr />
                <div className="row g-5">
                    <div className="col-md-6">
                        <h2>Accomplishment</h2>
                        <p>This game is a collaborative project between DASSAULT SYSTEMES and Compsim Brasil.</p>
                        <ul className="icon-list ps-0">
                            <li className="text-muted d-flex align-items-start mb-1"></li>
                        </ul>
                    </div>

                    <div className="col-md-6">
                        <h2>Help</h2>
                        <p>To get help for this simulator we have the following points of contact:</p>
                        <ul className="icon-list ps-0">
                            <li className="d-flex align-items-start mb-1"><a href="mailto:suporte@compsim.com.br">Support Compsim</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
}