export interface NineBoxQuadrant {
  name: string;
  color: string;
  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;
}

export const NINE_BOX: NineBoxQuadrant[] = [
  {
    name: "Baixa Produtividade, Baixa Inovação",
    color: "rgba(255, 133, 177, 0.4)",
    xMin: 0,
    xMax: 0.33,
    yMin: 0,
    yMax: 0.33,
  },
  {
    name: "Média Produtividade, Baixa Inovação",
    color: "rgba(255, 177, 177, 0.4)",
    xMin: 0,
    xMax: 0.33,
    yMin: 0.33,
    yMax: 0.67,
  },
  {
    name: "Alta Produtividade, Baixa Inovação",
    color: "rgba(133, 177, 255, 0.4)",
    xMin: 0,
    xMax: 0.33,
    yMin: 0.67,
    yMax: 1,
  },
  {
    name: "Baixa Produtividade, Média Inovação",
    color: "rgba(255, 177, 177, 0.4)",
    xMin: 0.33,
    xMax: 0.67,
    yMin: 0,
    yMax: 0.33,
  },
  {
    name: "Média Produtividade, Média Inovação",
    color: "rgba(133, 177, 255, 0.4)",
    xMin: 0.33,
    xMax: 0.67,
    yMin: 0.33,
    yMax: 0.67,
  },
  {
    name: "Alta Produtividade, Média Inovação",
    color: "rgba(177, 255, 133, 0.4)",
    xMin: 0.33,
    xMax: 0.67,
    yMin: 0.67,
    yMax: 1,
  },
  {
    name: "Baixa Produtividade, Alta Inovação",
    color: "rgba(133, 177, 255, 0.4)",
    xMin: 0.67,
    xMax: 1,
    yMin: 0,
    yMax: 0.33,
  },
  {
    name: "Média Produtividade, Alta Inovação",
    color: "rgba(177, 255, 133, 0.4)",
    xMin: 0.67,
    xMax: 1,
    yMin: 0.33,
    yMax: 0.67,
  },
  {
    name: "Alta Produtividade, Alta Inovação",
    color: "rgba(80, 177, 80, 0.4)",
    xMin: 0.67,
    xMax: 1,
    yMin: 0.67,
    yMax: 1,
  },
];

export function getNineBoxQuadrantIndex(x: number, y: number): number {
  return NINE_BOX.findIndex(
    (q) => x >= q.xMin && y >= q.yMin && x <= q.xMax && y <= q.yMax
  );
}

export function getNineBoxQuadrant(
  x: number,
  y: number
): NineBoxQuadrant | undefined {
  const index = getNineBoxQuadrantIndex(x, y);
  return index >= 0 ? NINE_BOX[index] : undefined;
}
