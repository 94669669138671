import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react-lite";
import { ReactNode } from "react";
import AppLayout from "../../components/AppLayout";
import TrackSelect from "./TrackSelect";

class IntroState {
    current = 0;
    get hasPrevious() {
        return this.current > 0;
    }
    get hasNext() {
        return this.current < 3;
    }
    next() {
        this.current++;
    }
    previous() {
        this.current--;
    }
    constructor() {
        makeAutoObservable(this);
    }
}
const introState = new IntroState();

const IntroItem = observer((props: { children: ReactNode }) => {

    return (
        <div className="centered-blurred-message fade-in">
            {props.children}
            <div style={{
                display: "flex",
                gap: "10px",
                justifyContent: "center"
            }}>
                {introState.hasPrevious ? (
                    <button className="btn btn-secondary" onClick={() => introState.previous()}>
                        &laquo; Back
                    </button>
                ) : null}

                <button className="btn btn-primary" onClick={() => introState.next()}>
                    Next &raquo;
                </button>
            </div>
        </div>
    );
});

function Intro1() {
    return (
        <IntroItem>
            <h1><b>3D</b>EXPERIENCE Game</h1>
            <hr />
            <div>
                <p className="lead">
                    Your company, after these years of Pandemic, has been looking for ways to grow and regain momentum and the scenario is now much more challenging.
                </p>
                <hr />
            </div>
            <div>
                <p className="lead">
                    Your company faces a change in its customer profile,
                    (with the loss of some recurring customers and the emergence of new opportunities).
                </p>
                <hr />
            </div>
        </IntroItem>
    );
}

function Intro2() {
    return (
        <IntroItem>
            <div>
                <p className="lead">
                    The financial impact of all those involved was great and topics such as: cost reduction,
                    agility and flexibility in the way of working become important points.
                </p>
                <hr />
            </div>
            <div>
                <p className="lead">
                    The market has been demanding new products and services
                    and your department will be increasingly pressured to meet these requests.
                </p>
                <hr />
            </div>
            <div>
                <p className="lead">
                    Some customers from geographies never accessed before started to contact
                    your company to understand if you are able to serve them.
                </p>
                <hr />
            </div>
        </IntroItem>
    );
}

function Intro3() {
    return (
        <IntroItem>
            <div>
                <p className="lead">
                    Bearing this challenge in  mind, respond the questions that will appear following 
                    according to the current status of your company and the way in which you react to the challenges.
                </p>
                <hr />
            </div>
            <div>
                <p className="lead">
                    Do not consider how you would like it to be done, but the way it is currently done.
                </p>
                <hr />
            </div>
            <div>
                <p className="lead">
                    Go ahead and choose between the engineering or manufacturing team to continue.
                </p>
                <hr />
            </div>
        </IntroItem>
    );
}

const IntroductionContent = observer(() => {
    switch (introState.current) {
        case 0: return <Intro1 />;
        case 1: return <Intro2 />;
        case 2: return <Intro3 />;
        default: return <TrackSelect />;
    }
});

export default function Introduction() {
    return (
        <AppLayout
            backgroundImg="/img/bg-office.jpg"
            justifyContent="center"
            alignItems="center">
            <IntroductionContent />
        </AppLayout>
    );
}