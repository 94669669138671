import { observer } from "mobx-react-lite";
import { questionState } from "../../services/Question";
import { randomize } from "../../utils/Arrays";
import QuestionAsk from "./QuestionAsk";
import QuestionNotFound from "./QuestionNotFound";
import QuestionOptions from "./QuestionOptions";

export default observer(function Question() {
  const question = questionState.question;
  if (!question) return <QuestionNotFound />;
  const randOptions = randomize(question.options);
  return questionState.optionsVisible ? (
    <QuestionOptions options={randOptions} />
  ) : (
    <QuestionAsk />
  );
});
