export default function AltaProdutividadeMediaInovacao() {
  return (
    <div>
      <h1 className="display-1">High Productivity, Average Innovation</h1>
      <p className="lead">
        Based on decisions made during the game, your company has current market
        demands in terms of productivity. <br />
        However, the low investment in the innovation of its processes,
        indicated by your actions during the game, raises the possibility that
        your Current costs to maintain this high productivity may be a little
        above what they should. <br />
        Furthermore, entering new markets can be linked to the innovation
        capacity of its processes.
      </p>
    </div>
  );
}
