export function shuffle<T>(array: T[]): T[] {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }

    return array;
}

export function randomPick<T>(array: T[]): T {
    return array[Math.floor(Math.random() * array.length)];
}

export function combineClasses(array?: (string | undefined | null)[]): string | undefined {
    return array?.filter(f => f ? true : false).join(" ");
}

export function textFilter<T>(array: T[], filter: string): T[] {
    const regex = new RegExp(filter, "i");
    const ret = array.filter(item => {
        return Object
            .values(item)
            .map((v) => {
                return regex.test(v);
            })
            .filter(v => v);
    });
    return ret;
}

/**
 * Compares two strings.
 * 
 * @param a 
 * @param b 
 * @returns 
 */
export function stringCompare(a?: string | null, b?: string | null): number {
    if (a) {
        if (b) {
            return b === a ? 0 : (b > a ? 1 : -1);
        } else {
            return -1;
        }
    } else if (b) {
        return 1;
    } else {
        return 0;
    }
}

export const stringCompareDesc = (a?: string | null, b?: string | null): number => -stringCompare(a, b);

export function randomize<T>(arr?: T[]): T[] {
    if (!arr) return [];
    const a = [...arr];
    for (let i = 1; i < a.length; i++) {
        if (Math.random() >= 0.5) {
            const tmp = a[i];
            a[i] = a[i - 1];
            a[i - 1] = tmp;
        }
    }
    return a;
}

