import { ReactElement, ReactNode } from "react";

export interface NavPanel {
  name: string;
  content: ReactElement | ReactNode;
  visible?: boolean;
}

export default function Panels(props: {
  panels: NavPanel[];
  selected?: number;
  onSelect: (index: number) => void;
}) {
  return (
    <div>
      <ul className="nav nav-tabs">
        {props.panels.map((panel, index) => {
          if (panel.visible === false) return null;
          return index === props.selected ? (
            <li className="nav-item" key={index}>
              <span className="nav-link active" aria-current="page">
                {panel.name}
              </span>
            </li>
          ) : (
            <li className="nav-item" key={index}>
              <span
                className="nav-link pointer"
                aria-current="page"
                onClick={() => props.onSelect(index)}
              >
                {panel.name}
              </span>
            </li>
          );
        })}
      </ul>
      <div>{props.panels[props.selected || 0]?.content}</div>
    </div>
  );
}
