import { IQuestion } from "../../types/QuestionInterface";

export default {
  questionId: "3ds_q5",
  context: [
    "The commercial team called me for a meeting to comment that we have a potential client in the US that is very different from what we are used to, but extremely promising.",
    "This customer wants to bring a new product. In the discussion with the commercial team, we had the doubt whether our current process will require the creation of several physical prototypes, if so, this will increase the value of the proposal due to the costs generated.",
  ],
  content: [
    "Can we handle this project without needing many physical prototypes?",
  ],
  options: [
    {
      text: "Internally we work with calculations and some partner companies make the physical prototypes. We will have to consult them to see the costs.",
      xValue: 0.25,
      yValue: 0.25,
      feedbackContent:
        "To attend projects without needing many prototypes, it is necessary to understand that when outsourced, it becomes a more complex step to maintain the quality standard, project time management and certification of compliance with the requirements, not including the additional cost for the service provided.",
    },
    {
      text: "No, the only way to run these tests is to do the physical prototypes in-house.",
      xValue: 0,
      yValue: 0.25,
      feedbackContent:
        "Making physical prototypes has a direct impact on development and especially on sustainability, currently there are technologies to perform simulations that help in checking requirements, drastically reducing the creation of prototypes, which absurdly raise the cost of the project and the time to launch the product on the market.",
    },
    {
      text: "To avoid the high costs of physical prototyping, we can outsource the creation of virtual prototyping. Even so, there will be an additional cost and a longer design time.",
      xValue: 0.5,
      yValue: 0.75,
      feedbackContent:
        "To attend projects without needing many prototypes, it is necessary to understand that when outsourced, it becomes a more complex step to maintain the quality standard, project time management and certification of compliance with the requirements, not including the additional cost for the service provided.",
    },
    {
      text: "We have a solution for developing virtual prototypes that drastically reduces design time and the need for physical prototypes.",
      xValue: 1,
      yValue: 1,
      extension: {
        type: "OpenAnswer",
        title:
          "What is the solution used to develop virtual prototypes?",
      },
      feedbackContent:
        "Having a complete solution that can be designed and all simulations (Structural, Fluid, Impact) will bring reliability to the non-construction of a physical prototype, the more robust the solution, the product is developed in less time and provides financial compensation.",
    },
  ],
  backgroundImage: "/img/cafeteria/bg-entrada.jpg",
  characterName: "Melissa - Engineering Manager",
  sceneNumber: 6,
} as IQuestion;
