import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router-dom";
import AppLayout from "../../components/AppLayout";
import { authenticationState } from "../../services/Authentication";
import Authentication from "../authentication/AuthenticationWall";
import EnrollmentView from "./enrollment-view";
import Enrollments from "./enrollments";
import ReportsHome from "./home";
import PossibleAnswerDistribution from "./PossibleAnswerDistribution";
import QuestionsReport from "./questions";

export default observer(function Reports() {
  return (
    <Authentication>
      <AppLayout justifyContent="flex-start">
        {authenticationState.isAdmin ? (
          <Routes>
            <Route
              path="possible-answer-distribution"
              element={<PossibleAnswerDistribution />}
            />
            <Route path="enrollments" element={<Enrollments />} />
            <Route path="enrollment-view/:id" element={<EnrollmentView />} />
            <Route path="questions" element={<QuestionsReport />} />
            <Route path="" element={<ReportsHome />} />
          </Routes>
        ) : (
          <div className="container">
            <div className="alert alert-danger">
              You need to be admin to view reports.
            </div>
          </div>
        )}
      </AppLayout>
    </Authentication>
  );
});
