import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import AppLayout from "../../components/AppLayout";
import NineBox from "../../components/NineBox";
import enrollmentState from "../../services/Enrollment";
import { lastQuestion } from "../../services/Question";
import CompleteAnswersTable from "./CompleteAnswersTable";
import NineboxDescription from "./ninebox-description";

export default observer(function SceneTalksComplete() {
  const point = [enrollmentState.xValue, enrollmentState.yValue];
  useEffect(() => {
    document.body.setAttribute("class", "");
    document.body.setAttribute("style", "");
  });
  return (
    <AppLayout>
      <div className="container">
        <h1>Congratulations!</h1>
        <p className="lead">You have finished the simulation!</p>
        <button className="btn btn-secondary" onClick={lastQuestion}>
          &laquo; Go back
        </button>
        <NineBox points={[point]} />
        <div style={{ marginBottom: "20px" }}>
          <NineboxDescription point={point} />
        </div>
        <CompleteAnswersTable enrollment={enrollmentState.enrollment!} />
      </div>
    </AppLayout>
  );
});
